<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="mb-0">
          Data Siswa
        </h4>
      </template>
      <b-row>
        <b-col cols="4" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar :src="studentDatas.photoprofile" text="" :variant="`light-primary`" size="104px" rounded />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ studentDatas.name }}
                </h4>
                <span class="card-text"> {{ studentDatas.nisn }}</span>
              </div>
              <div class="d-flex flex-wrap" />
            </div>
          </div>
        </b-col>

        <b-col cols="8">
          <b-row>
            <b-col cols="6" class="border-left">
              <h5 class="mb-0">
                Kelas
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentClass }}
              </div>
              <h5 class="mb-0">
                TA
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentSemester }}
              </div>
              <h5 class="mb-0">
                Tempat & Tgl Lahir
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentBirth }}
              </div>
              <h5 class="mb-0">
                Jenis Kelamin
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentGender }}
              </div>
              <h5 class="mb-0">
                Agama
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentReligion }}
              </div>
            </b-col>
            <b-col cols="6" class="border-left">
              <h5 class="mb-0">
                Alamat
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentAddress }}
              </div>
              <h5 class="mb-0">
                Jarak Ke Sekolah
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentDistance }} Km
              </div>
              <h5 class="mb-0">
                Anak ke/dari
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentStatus }}
              </div>
              <h5 class="mb-0">
                Golongan darah
              </h5>
              <div class="mb-2 text-secondary">
                {{ studentBlood }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import dayjs from 'dayjs'
import 'dayjs/locale/id'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    studentDatas: Object,
  },
  data: () => ({
    // studentDatas: {},
  }),
  computed: {
    studentClass() {
      return this.studentDatas?.kelas
    },
    studentBirth() {
      return `${this.studentDatas?.birth_place}, ${dayjs(
        this.studentDatas?.birth_date,
      )
        .locale('id')
        .format('D MMMM YYYY')}`
    },
    studentAddress() {
      return this.studentDatas?.address
    },
    studentGender() {
      return this.studentDatas?.gender === 'M' ? 'Laki-Laki' : 'Perempuan'
    },
    studentReligion() {
      switch (this.studentDatas?.religion) {
        case 1:
          return 'Islam'
        case 2:
          return 'Protestan'
        case 3:
          return 'Katolik'
        case 4:
          return 'Hindu'
        case 5:
          return 'Budha'
        default:
          return 'Konghuchu'
      }
    },
    studentDistance() {
      return this.studentDatas?.distance
    },
    studentStatus() {
      return `${this.studentDatas?.child_number}/${this.studentDatas?.child_number}`
    },
    studentBlood() {
      return this.studentDatas?.blood_type
    },
    studentSemester() {
      return this.studentDatas?.student_years?.data?.name
    },
  },
}
</script>

<style></style>
