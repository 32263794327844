<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="mb-0">
          Data Orang Tua
        </h4>
      </template>
      <b-row class="">
        <b-col cols="6">
          <h4 class="border-bottom">
            Bagian Ayah
          </h4>
          <h5 class="mb-0">
            Nama Ayah
          </h5>
          <div class="mb-2 text-secondary">
            {{ name(0) }}
          </div>
          <h5 class="mb-0">
            Tempat & Tangga Lahir
          </h5>
          <div class="mb-2 text-secondary">
            {{ birth(0) }}
          </div>
          <h5 class="mb-0">
            Pendidikan Terakhir
          </h5>
          <div class="mb-2 text-secondary">
            {{ education(0) }}
          </div>
          <h5 class="mb-0">
            Pekerjaan
          </h5>
          <div class="mb-2 text-secondary">
            {{ profession(0) }}
          </div>
          <h5 class="mb-0">
            Penghasil Perbulan
          </h5>
          <div class="mb-2 text-secondary">
            {{ salary(0) }}
          </div>
          <h5 class="mb-0">
            Agama
          </h5>
          <div class="mb-2 text-secondary">
            {{ religion(0) }}
          </div>
          <h5 class="mb-0">
            Alamat
          </h5>
          <div class="mb-2 text-secondary">
            {{ address(0) }}
          </div>
          <h5 class="mb-0">
            Status
          </h5>
          <div class="mb-2 text-secondary">
            Ayah {{ status(0) }}
          </div>
        </b-col>
        <b-col cols="6">
          <h4 class="border-bottom">
            Bagian Ibu
          </h4>
          <h5 class="mb-0">
            Nama Ibu
          </h5>
          <div class="mb-2 text-secondary">
            {{ name(1) }}
          </div>
          <h5 class="mb-0">
            Tempat & Tangga Lahir
          </h5>
          <div class="mb-2 text-secondary">
            {{ birth(1) }}
          </div>
          <h5 class="mb-0">
            Pendidikan Terakhir
          </h5>
          <div class="mb-2 text-secondary">
            {{ education(1) }}
          </div>
          <h5 class="mb-0">
            Pekerjaan
          </h5>
          <div class="mb-2 text-secondary">
            {{ profession(1) }}
          </div>
          <h5 class="mb-0">
            Penghasil Perbulan
          </h5>
          <div class="mb-2 text-secondary">
            {{ salary(1) }}
          </div>
          <h5 class="mb-0">
            Agama
          </h5>
          <div class="mb-2 text-secondary">
            {{ religion(1) }}
          </div>
          <h5 class="mb-0">
            Alamat
          </h5>
          <div class="mb-2 text-secondary">
            {{ address(1) }}
          </div>
          <h5 class="mb-0">
            Status
          </h5>
          <div class="mb-2 text-secondary">
            Ibu {{ status(1) }}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import dayjs from 'dayjs'
import 'dayjs/locale/id'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    studentDatas: Object,
  },
  data: () => ({
    // studentDatas: {},
  }),
  methods: {
    familyStudent(indexParent) {
      return this?.studentDatas?.student_families?.data[indexParent]
    },
    name(indexParent) {
      return this.familyStudent(indexParent)?.name
    },
    birth(indexParent) {
      return `${this.familyStudent(indexParent)?.birth_place}, ${dayjs(
        this.familyStudent(indexParent)?.birth_date,
      )
        .locale('id')
        .format('D MMMM YYYY')}`
    },
    education(indexParent) {
      return this.familyStudent(indexParent)?.level_education?.data
        ?.level_education_desc
    },
    profession(indexParent) {
      return this.familyStudent(indexParent)?.profession
    },
    salary(indexParent) {
      const nf = new Intl.NumberFormat('id-ID')
      return `Rp ${nf.format(this.familyStudent(indexParent)?.salary)},-`
    },
    religion(indexParent) {
      switch (this.familyStudent(indexParent)?.religion) {
        case 1:
          return 'Islam'
        case 2:
          return 'Protestan'
        case 3:
          return 'Katolik'
        case 4:
          return 'Hindu'
        case 5:
          return 'Budha'
        default:
          return 'Konghuchu'
      }
    },
    address(indexParent) {
      return this.familyStudent(indexParent)?.address
    },
    status(indexParent) {
      switch (this.familyStudent(indexParent)?.parental_status) {
        case 1:
          return 'Kandung'
        case 2:
          return 'Tiri'
        default:
          return 'Angkat'
      }
    },
  },
}
</script>

<style></style>
